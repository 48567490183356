/**
 * Automatic Index (vscode snippet: df-index)
 * 1. Install the extension: JayFong.generate-index
 * 2. Press [ ⌘-K + I ] to generate the code (or invoke command 'Generate Index')
 *
 * @see vscode-generate-index https://github.com/fjc0k/vscode-generate-index
 * @see change-case https://github.com/blakeembrey/change-case/tree/main/packages/change-case
 *
 * ## Cheat Sheet
 *
 * @index(
 *    patterns: string | string[],
 *    codeGenerator: (
 *      parsedPath: {path, name, ext},
 *      changeCase: { noCase, pathCase, camelCase, ...}, // all props are fn(string) => string
 *      extraInfo: { total, index, isFirst, isLast, isDir, isFile }
 *     ) => string,
 *    globbyOptions?: GlobbyOptions,
 * ) => string
 */

import type { OneTimeAction } from '~/libs/api-client';
import type { OneTimeActionRunner } from '../types';

// @index('./**'+'/*.(ts|tsx)', (f, _, info) => `import ${_.camelCase(f.name)} from '${f.path}';`)
import askForEmailConsent from './ask-for-email-consent';
import reportUserSignup from './report-user-signup';
//@endindex

export const ACTION_RUNNERS: Record<OneTimeAction, OneTimeActionRunner> = {
  // @index('./**'+'/*.(ts|tsx)', (f, _, info) => `[${_.camelCase(f.name)}.action]: ${_.camelCase(f.name)},`)
  [askForEmailConsent.action]: askForEmailConsent,
  [reportUserSignup.action]: reportUserSignup,
  // @endindex
};
