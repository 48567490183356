import type { OneTimeAction } from '~/libs/api-client';
import { type AuthUser } from '~/libs/api-client';
import { getUser } from '~/store/user';
import type { OneTimeActionExecutionContext } from './types';

import { api } from '~/modules/api';
import { ACTION_RUNNERS } from './runners';

export class Action {
  constructor(public readonly id: OneTimeAction) {}

  get isCompleted(): boolean {
    return Boolean(localStorage?.getItem(`completedActions.${this.id}`));
  }

  get isPending(): boolean {
    return Boolean(
      this.hasRunner &&
        !this.isCompleted &&
        getUser()?.pendingActions.includes(this.id),
    );
  }

  get hasRunner() {
    return Boolean(ACTION_RUNNERS[this.id]);
  }

  private async complete(user?: AuthUser) {
    if (this.isCompleted) return;

    if (user) {
      user.pendingActions = user?.pendingActions.filter(id => id !== this.id);
    }

    await api.User.removePendingAction({
      path: {
        oneTimeAction: this.id,
      },
    }).then((res: { error: any }) => {
      if (res.error) {
        console.error(
          `Error marking action '${this.id}' as completed:`,
          res.error,
        );
        return;
      }

      localStorage?.removeItem(`completedActions.${this.id}`);
    });
  }

  public async run(ctx: OneTimeActionExecutionContext) {
    if (this.hasRunner && this.isPending) {
      await ACTION_RUNNERS[this.id].run(ctx);

      await this.complete(ctx.user);
    }
  }
}
