import type { Component } from 'solid-js';
import { getUser } from '~/store/user';
import { Action } from './Action';
import { ACTION_RUNNERS } from './runners';
import type { OneTimeActionExecutionContext } from './types';

let actionsScheduled = false;

async function runOneTimeActions() {
  const user = getUser();
  const runnableActions = (user?.pendingActions || [])
    .map(id => new Action(id))
    .filter(a => a.hasRunner);

  for (const action of runnableActions) {
    if (action.isPending) {
      await action.run({ user });
    }
  }
}

export function scheduleOneTimeActions() {
  console.log('🅰️ scheduleOneTimeActions');
  if (actionsScheduled) return;

  if (document.readyState === 'complete') {
    runOneTimeActions();
  } else {
    window.addEventListener('load', runOneTimeActions);
  }

  actionsScheduled = true;
}

export const OneTimeActionsProvider: Component<
  OneTimeActionExecutionContext
> = (props: OneTimeActionExecutionContext) => {
  scheduleOneTimeActions();

  const runnerComponents = Object.values(ACTION_RUNNERS)
    .map(runner => runner.component)
    .filter((c): c is Component<OneTimeActionExecutionContext> => !!c);

  console.log('🅰️ OneTimeActionsProvider', runnerComponents);

  return <>{runnerComponents.map(comp => comp(props))}</>;
};
